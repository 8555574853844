//项目入口文件
import dva from "dva";
import { persistEnhancer } from 'dva-model-persist';
import router from "@/router";
import history from "@/utils/history";
import {createModel} from "@/store";
import createLoading from 'dva-loading';
import { message } from 'antd';
import "@/assets/css/mixin.scss";
import "@/assets/css/style.scss";
import "@/assets/css/common.scss";
import "@/assets/css/iconfont.scss";
import url from "url";
const NODE_ENV = process.env.NODE_ENV;
if (NODE_ENV === "development") {
    // window.API_HOST = "http://192.168.8.131:12001";
    // window.API_HOST = "http://pay-dev.qihuian.com";
    // window.IMAGE_HOST = "http://pay-dev.qihuian.com";
    window.IMAGE_HOST = "http://pay-test.qihuian.com";
    window.API_HOST = "http://pay-test.qihuian.com";
} else {
    window.API_HOST = "https://pay.qihuian.com";
    window.IMAGE_HOST = "https://pay.qihuian.com";
}

const app = dva({
    history: history,
    onError(e) {
        message.error(e.message);
    },
    onStateChange(state){
        // console.log("dva里面的值："+ JSON.stringify(state))
    }
    // onReducer(reducer){
    //     const persistConfig = {
    //         key: 'root',
    //         storage,
    //     };
    //     return persistReducer(persistConfig, reducer);
    // }
});
createModel(app);
app.router(router);
app.use(createLoading());
app.use({
    extraEnhancers: [
        persistEnhancer()
    ],
});
app.start("#root");
// 页面跳转
if(app._history.location.search.includes('result')){
    let result = url.parse(app._history.location.search, true).query.result
    if(result.includes('"preOpen"')) {  // 开通流程
        if(result.includes('"supplementaryInfo"') || result.includes('"continueOpenFlow"')){  // 开通--补充联系人信息
            app._history.push(`/admin/yeePayOpen/open?user=${result}`);
        } else if(result.includes('"merchantHandlerAuth"')){  // 开通--联系人认证
            app._history.push(`/admin/yeePayOpen/openCertification?user=${result}`);
        }
    } else if(result.includes('"prePay"')){  // 支付
        app._history.push(`/admin/payCenter/payOrder?user=${result}`);
    } else if(result.includes('"preWithdraw"')){  // 提现
        app._history.push(`/admin/payCenter/recharge?user=${result}`);
    } else if(result.includes('"preRecharge"')){  // 充值
        app._history.push(`/admin/payCenter/topUp?user=${result}`);
    } else if(result.includes('"preBindCard"')){  // 绑卡
        app._history.push(`/admin/payCenter/addChannel?user=${result}`);
    } else if(result.includes('"preUnbindCard"')){  // 解绑
        app._history.push(`/admin/payCenter/unbundling?user=${result}`);
    } else if(result.includes('"prePayFlow"')){  // 交易流水
        app._history.push(`/admin/payCenter/dealFlow?user=${result}`);
    } else {  // 错误页面
        app._history.push(`/admin/payCenter/error`);
    }
}