import loadable from "../utils/loadable";

const App = loadable(() => import('@/containers/App'));
const PayCenter = loadable(() => import("../containers/payCenter"));
const YeePayOpen = loadable(() => import("../containers/yeePayOpen"));

export default [
  {
    path: "/admin",
    name: "支付系统-运营",
    component: App,
    children: [
      // 易宝支付
      {
        path: "/admin/payCenter/:item",
        name: "支付系统-运营",
        component: PayCenter,
      },
      // 易宝开通
      {
        path: "/admin/yeePayOpen/:item",
        name: "支付系统-运营",
        component: YeePayOpen,
      },
    ],
  },

  {
    redirect: "/",
    name: "defaultRoute",
    component: "/admin/payCenter/error",
  },
];
